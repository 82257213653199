import { mapState, mapGetters } from 'vuex'
import { trackEvent } from '../../../../../plugins/firebase'
import Metrics from '../../../../../plugins/metrics'

export default {
  name: 'StepFinal',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  components: {
    AnonymousMode: () => import('./components/Anonymous'),
    ArchiveMode: () => import('./components/Archive'),
    IdentifiedMode: () => import('./components/Identified'),
    ConnectMode: () => import('./components/Connect'),
  },

  data() {
    return {
      copied: false,
      showSnackbar: false,
      copyText: 'copy',
    }
  },

  mounted() {
    trackEvent('send_mode_view_final_step')

    const { source, channel, anonymous } = this.report
    const payloadMetrics = {
      source,
      channel,
      anonymous,
    }

    Metrics.track('Report Step Final Started', payloadMetrics)

    Metrics.track('Report Send Mode Page', payloadMetrics)

    window.scrollTo({ top: -100, behavior: 'smooth' })
  },

  computed: {
    ...mapState(['isAnonymousUser', 'loading']),
    ...mapGetters(['isConnectEnabled']),

    isReportAnonymous() {
      return this.report.anonymous
    },

    isReportArchived() {
      return this.report?.status === 'ARCHIVED'
    },

    isReportConnectPending() {
      return this.report?.status === 'PENDING_MATCH'
    },

    isFromCompanyChannel() {
      const { channel } = this.report
      return !!channel && channel !== 'SafeSpaceHomeApp'
    },

    hasInvolved() {
      return this.report?.involved.length
    },

    enableConnectMode() {
      const isStatusInvalid =
        this.isReportConnectPending || this.isReportArchived

      if (!this.isConnectEnabled || isStatusInvalid) {
        return false
      }

      return this.hasInvolved
    },

    hasArchiveMode() {
      const isStatusValid =
        !this.isReportArchived && !this.isReportConnectPending
      return !this.isReportAnonymous && isStatusValid
    },
  },

  methods: {
    submitAnonymously() {
      this.report.anonymous = true
      this.$emit('submit:reportAnonymous')
      this.submit()
    },

    submitToArchive() {
      this.report.archive = true
      this.submit()
    },

    submitIdentified(contact) {
      this.report.contactEmail = contact
      this.submit()
    },

    submit() {
      this.$emit('submit')
      trackEvent('report_submitted')
    },

    copy() {
      this.showSnackbar = true
      const element = document.getElementById('protocol')
      element.setAttribute('type', 'text')
      element.select()
      trackEvent('copy_code_clicked')

      try {
        document.execCommand('copy')
        this.copyText = 'copied'
      } catch (err) {
        console.log('Oops, unable to copy') // eslint-disable-line no-console
      }

      element.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },

    sendReportWithConnect() {
      this.report.match = true

      this.submit()
    },
  },
}

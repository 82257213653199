import { mapState, mapActions } from 'vuex'
import ReportForm from '../ReportForm/steps/StepFinal'
import SContainer from '@/components/ui/s-container'

import {
  SEND_ARCHIVED_REPORT,
  SEND_PENDING_CONNECT_REPORT,
} from '@/graphql/mutations'

export default {
  name: 'ReportPending',

  components: {
    ReportForm,
    SContainer,
  },

  props: {
    reportId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      anonymous: this.$attrs.anonymous || false,
      report: {},
      loading: false,
    }
  },

  async created() {
    this.loading = true
    this.report = await this.getReportById(this.reportId)
    this.loading = false
  },

  methods: {
    ...mapActions(['getReportById']),

    setReportForAnonymous() {
      this.report.anonymous = true
    },

    submit() {
      const { id, anonymous = false, match = false, status } = this.report

      const submitManager = {
        ARCHIVED: () => this.submitWithArchivedReport(id, anonymous, match),
        PENDING_MATCH: () => this.submitWithPendingConnectRepor(id, anonymous),
      }

      submitManager[status]()

      this.$router.push({
        name: 'report-success',
        params: {
          reportId: id,
        },
      })
    },

    submitWithArchivedReport(id, anonymous, match) {
      this.$apollo.mutate({
        mutation: SEND_ARCHIVED_REPORT,
        variables: {
          id,
          anonymous,
          match,
        },
      })
    },

    submitWithPendingConnectRepor(id, anonymous) {
      this.$apollo.mutate({
        mutation: SEND_PENDING_CONNECT_REPORT,
        variables: {
          id,
          anonymous,
        },
      })
    },
  },

  computed: {
    ...mapState(['files']),

    reportWithChannel() {
      return {
        ...this.report,
        channel: 'SafeSpaceHomeApp',
      }
    },
  },

  watch: {
    files() {
      this.report.files = this.files
      this.report.channel = 'SafeSpaceHomeApp'
      this.draft = this.report
    },
  },
}
